import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import { routes } from './routes/routes'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from '@mui/material'
import theme from './theme'
import FullScreenLoader from './components/UI/FullScreenLoader'
import ErrorBoundary from './components/UI/ErrorBoundary'
import GetTheme from './components/UI/GetTheme'
import { ProjectProvider } from './context/ProjectContext'
const router = createBrowserRouter(routes)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.Fragment>
    <ErrorBoundary>
      <ProjectProvider>
        <Suspense fallback={<FullScreenLoader loading={true} />}>
          <GetTheme />
          <Toaster position="top-right" />
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
            <FullScreenLoader />
          </ThemeProvider>
        </Suspense>
      </ProjectProvider>
    </ErrorBoundary>
  </React.Fragment>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
