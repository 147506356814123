import { tableFiltersUrl } from '../../utils/helper'
import request from '../axiosInterceptor'

const api = {
  getMasterDetails(data?: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/common/master-details?${data || 'workspace=true&language=true&theme=true&license_type=true&package_type=true&app_module=true'}`,
      method: 'get',
    })
  },
  getAllBot(data?: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/common/copilots-languages?${data || 'workspace=true&language=true&theme=true&license_type=true&package_type=true&app_module=true'}`,
      method: 'get',
    })
  },
  getAllCountries() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/common/countries-list`,
      method: 'get',
    })
  },
  getWebModules() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-roles/modules-list`,
      method: 'get',
    })
  },
  getWebPermission() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-roles/permission-list`,
      method: 'get',
    })
  },
  getAllDivision(data?: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/division-list?${data ? tableFiltersUrl(data) : ''}`,
      method: 'get',
    })
  },
  getAllHazardType(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/hazard-type-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllRootCause(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/root-cause-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllSeverity(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/severity-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllObservationStatus(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/observation-status-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllStatisticsCaptureStatus(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/statistic-capture-status-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllIncidentStatus(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/incident-status-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllIncidentCategory(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/incident-category-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAllIncidentSeverity(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/master/incident-severity-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
}

export default api
