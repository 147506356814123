import request from '../axiosInterceptor'

const api = {
  getAllTasks(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/incidents/incidents-list`,
      method: 'get',
    })
  },
  getAllObservation(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/observations/observations-list`,
      method: 'get',
    })
  },
  getAllIncidents(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/incidents/incidents-list`,
      method: 'get',
    })
  },
  getIncidentDetails(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/incidents/incident-details-by-incident-id?incident_details_id=${id}`,
      method: 'get',
    })
  },
  getObservationDetails(pId: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${pId}/observations/observation-detail?observation_details_id=${id}`,
      method: 'get',
    })
  },
  postHscHandle(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${data?.pId}/observations/hse-status`,
      method: 'post',
      data: data,
    })
  },
  postIncidentHsc(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${data?.pId}/incident/hse-status`,
      method: 'post',
      data: data?.form,
    })
  },
}

export default api
